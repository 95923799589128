import React, {useEffect, useState} from "react";
import { glanceSMS } from "../../services/apiServices";
import phone from "../../staticAssets/phone.png";
import gp from "../../staticAssets/gp.png";
import ap from "../../staticAssets/image 116.png";

function Sec5({ user }) {
  const [p, setp] = useState("");
  const [msg, setmsg] = useState("");
  const [isError,setIsError] = useState(false);

  const handleSend = async () => {

    var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?a-zA-Z]/;

    if(p.length != 10){
      setmsg("Phone number must have 10 digits!");
      setIsError(true);
    }else if(regex.test(p)){
      setmsg("Please enter a valid number!");
      setIsError(true);
    }else{
      let d = {
        phoneNumber: p,
        platform: "WEB",
      };
      let res = await glanceSMS(d);
      if (res.statusCode == 200) {
        setmsg(res.message);
        setp("");
        setIsError(false);
        setTimeout(() => {
          setmsg("");
        }, 5000);
      }
    }
  }
  return (
    <div className="container mx-auto">
      {user?.currencyType != "usd" && (
        <div className="h-[270px] w-full cardGrad overflow-hidden">
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 flex flex-col ml-6 md:ml-10 my-6 md:my-10">
              <h4 className="text-white work-sans text-2xl md:text-4xl font-semibold md:tracking-wide">
                Have a problem?
              </h4>
              <h4 className="text-white work-sans text-2xl md:text-4xl font-semibold md:tracking-wide">
                Talk to our astros.
              </h4>
              <h6 className="raleway text-sm md:text-base text-white tracking-wide mt-4 md:mt-6">
                {`Download & get ${
                  user?.currencyType == "usd" ? "$ 4" : "₹ 91"
                } in your account!`}
              </h6>
              <div className="h-12 bg-white rounded-xl w-11/12 md:w-3/4 mt-2 shadow-md px-2 md:px-4 flex items-center raleway">
                <input
                  className="flex-1 focus:outline-none text-[12px] md:text-sm "
                  placeholder="Enter Mobile Number to get download link"
                  value={p}
                  onChange={(e) => {
                    setp(e.target.value);
                  }}
                />
                <div className="w-0.5 rounded-full h-8 bg-gray-500/20 text-sm"></div>
                <a
                  className="cursor-pointer text-[#FB5B1D] ml-2 text-[12px] md:text-sm"
                  onClick={handleSend}
                >
                  Send
                </a>
              </div>
              <h6 className={`${isError ? "text-black" : "text-white"} text-sm raleway m-1`}>{msg}</h6>
            </div>
            <div className="flex flex-col h-0 w-0 invisible md:visible md:h-[270px] md:w-[400px] justify-end relative">
              <img
                src={phone}
                className="absolute -bottom-[2.2rem]"
                width="600"
                height="200"
                alt="Phone Image"
              />
            </div>
            <div className="flex flex-row md:flex-col items-center justify-evenly md:ml-16 md:mr-10 gap-4">
              <a
                className="cursor-pointer"
                href="https://bit.ly/BodhiWeb"
                aria-label="Play Store Link"
              >
                <img
                  loading="lazy"
                  src={gp}
                  width="200"
                  height="100"
                  className="h-[40px] md:h-[60px] object-contain"
                  alt="Google Play Store Image"
                />
              </a>
              <a
                className="cursor-pointer"
                href="https://bit.ly/BodhiWeb"
                aria-label="App Store Link"
              >
                <img
                  loading="lazy"
                  src={ap}
                  width="200"
                  height="100"
                  className="h-[40px] md:h-[60px] object-contain"
                  alt="App Store Image"
                />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sec5;
