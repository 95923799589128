import React, {useState, useRef} from 'react';
import ban1 from "../../staticAssets/ban1.png";
import Vec from "../../staticAssets/vec.svg";

function Sec3() {
  const [onplay, setonplay] = useState(false);
  return (
    <div className="bg-[url('../staticAssets/mgbg.png')] bg-center h-[63rem] md:h-[100rem] flex flex-col">
            <div className="flex w-full justify-center mt-[5rem] md:mt-[10rem]">
              <div className="relative flex items-center justify-center rounded-xl overflow-hidden shadow-xl" >
                {!onplay && <div className="rounded-xl overflow-hidden h-[300px] md:h-[315px] w-[560px] relative flex items-center justify-center">

                  <img className="z-0 h-[300px] md:h-[315px] w-auto object-cover" width="400" height="400" src="https://i.ytimg.com/vi/zrRt9i4PSiA/maxres2.jpg?sqp=-oaymwEoCIAKENAF8quKqQMcGADwAQH4Ad4DgALgA4oCDAgAEAEYfyATKBMwDw==&rs=AOn4CLBwrOtRU1yndy7Q3B-3fE94J61Ydw"  alt='Problems in personal life?'/>
                  <button className="absolute h-full" onClick={()=>{setonplay(true);}} aria-label='Menu Button'>

                  <svg height="15%" version="1.1" viewBox="0 0 68 48" width="100%"><path d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg>
                  </button>
                </div>
                }
                {onplay &&<iframe
                  className="rounded-xl overflow-hidden h-[300px] md:h-[315px] w-[560px]"
                  src="https://www.youtube.com/embed/zrRt9i4PSiA?autoplay=1"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>}
              </div>
            </div>
            <div className="mt-[2rem] md:mt-[15rem] flex flex-col md:flex-row md:mx-20">
              <div className="flex gap-1 md:gap-8 order-2 md:order-1">
                <div className="h-[230px] md:h-[374px] w-[170px] md:w-[295px] shadow-xl bg-gradient-to-b from-[#FD9C67] to-[#DD4FB1] rounded-xl relative overflow-hidden flex flex-col justify-end ml-2">
                  <img
                    loading="lazy"
                    className="absolute top-0 -left-4 md:-left-8 h-[200px] md:h-[374px] w-[160px] md:w-[295px] object-cover"
                    src={ban1}
                    alt="Lotus banner"
                  />
                  <h4 className="raleway text-white text-base md:text-2xl font-semibold ml-2 md:ml-4 mr-0 md:mr-4 drop-shadow-lg">
                    Do Yoga, Meditation, Mantra chanting & More
                  </h4>
                  <a
                    className="text-center cursor-pointer text-white my-4 font-semibold raleway z-40 tracking-wide text-sm md:text-base"
                    href="https://bit.ly/BodhiWeb"
                  >
                    Download the App
                  </a>
                </div>
                <div className="h-[230px] md:h-[374px] w-[170px] md:w-[295px] shadow-2xl bg-white rounded-xl relative overflow-hidden flex flex-col justify-between mt-24 ">
                  <Vec className="mx-auto my-2 md:my-6" />
                  <h4 className="raleway text-black text-base md:text-2xl font-semibold ml-2 md:ml-4 mr-0 md:mr-4 drop-shadow-lg">
                    Get FREE Horoscope
                  </h4>
                  <h5 className="raleway text-black text-xs md:text-base ml-2 md:ml-4 mr-0 md:mr-4 drop-shadow-lg">
                    Also try out our compatibility tool with your partner for
                    FREE
                  </h5>
                  <a
                    className="text-center cursor-pointer text-[#FB5B1D] my-4 font-semibold raleway z-40 tracking-wide text-sm md:text-base"
                    href="https://bit.ly/BodhiWeb"
                  >
                    Download the App
                  </a>
                </div>
              </div>
              <div className="col-span-3 mt-0 md:mt-28 order-1 md:order-3 ml-2 md:ml-10 mb-8">
                <div className="flex flex-col">
                  <h4 className="playfair text-[30px] md:text-[50px] text-black font-bold leading-tight">
                    Get Daily Horoscope, Yoga and Mantra Chanting remedies for
                    FREE!
                  </h4>
                </div>
              </div>
            </div>
          </div>
  )
}

export default Sec3