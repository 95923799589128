import React from 'react'
import AstroCardNew from '../AstroCardNew';
import {navigate} from 'gatsby';

function Sec2({widgets, setStatus, astrologerId}) {
  return (
    <div className="container mx-auto flex flex-col justify-center">
            <h1 className="text-black text-[25px] md:text-[50px] font-bold playfair text-center">
              Top Astrologers
            </h1>

            <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
              <div className="flex flex-nowrap lg:ml-10 md:ml-20 ml-2">
                {widgets.slice(0, 5)?.map((v, i) => {
                  return (
                    <AstroCardNew
                      key={i}
                      data={v}
                      astrologerId={astrologerId}
                      setStatus={setStatus}
                    />
                  );
                })}
              </div>
            </div>
            <div className="w-full flex justify-center">
              <button
                className="w-3/4 md:w-1/3 border border-[#FB5B1D] py-2 rounded-xl bg-white shadow-md text-[#FB5B1D] tracking-wide worksans text-base md:text-lg"
                onClick={() => {
                  navigate("/consultAstro");
                }}
              >
                View All Astrologers
              </button>
            </div>
          </div>
  )
}

export default Sec2