import React from "react";
import gopi from "../../staticAssets/gp.jpeg";
import b2 from "../../staticAssets/b2.png";
import med from "../../staticAssets/med.png";
import peace from "../../staticAssets/peace.png";
import tbg from "../../staticAssets/tbg.png";
import { navigate } from "gatsby";

function Sec1(props) {
  const { user } = props;
  return (
    <div className="h-[135vh] md:h-[91vh] ss relative z-0">
      <div className="absolute top-0 w-screen h-[135vh] md:h-[90vh] curvesm bg-white z-0">
        <div className="w-full flex justify-end">
          <img src={tbg} className="object-contain w-full md:w-10/12" width="100" height="100" alt="background"/>
        </div>
      </div>
      <div className="flex flex-col md:flex-row w-screen z-10 p-4">
        <div className="w-full md:w-1/2 h-auto md:h-[65vh] flex flex-col z-10 items-end justify-center mx-4">
          <div className="flex flex-col w-full md:w-5/6 mx-4">
            <h1 className="text-black playfair text-[25px] md:text-[60px] font-bold leading-tight">
              Worried about your job or love-life?
            </h1>
            <h4 className="text-base md:text-lg text-black mt-4 worksans">
              Get guidance and daily remedies from the top astrologers.
            </h4>
            <button
              className="invisible md:visible h-0 md:h-[48px] but w-0 md:w-full lg:w-3/4 mt-4 transform transition duration-300 hover:scale-[1.02] hover:-translate-y-1 text-white tracking-wide worksans text-base md:text-lg"
              onClick={() => {
                navigate("/consultAstro");
              }}
            >
              {user.name !== undefined
                ? `Consult Top Astrologers Now!`
                : `Login & get ${
                    user?.currencyType == "usd" ? "$ 4" : "₹ 91"
                  } in your account!`}
            </button>
          </div>
        </div>
        <div className="w-full md:w-1/2 h-[40vh] md:h-[65vh] relative flex items-center z-40">
          <div className="absolute left-[0.2rem] md:left-20 h-[230px] md:h-[350px] w-[180px] md:w-[272px] bg-[#F98A28] flex flex-col items-center justify-between card p-2 z-40">
            <div className="rounded overflow-hidden w-full h-full bg-white/20 card p-[1px]">
              <div className="bg-[#F98A28] card h-full flex flex-col items-center justify-between rounded overflow-hidden">
                <div className="flex flex-col items-center">
                  <h2 className="text-white worksans font-semibold text-[0.8rem] md:text-lg tracking-wide mt-4">
                    Consult Astrologers
                  </h2>
                  <h6 className="text-white worksans text-[0.7rem] md:text-base">
                    Chat or Call Now
                  </h6>
                </div>
                <img
                  loading="lazy"
                  src={gopi}
                  className="object-contain rounded mt-4"
                  width="300" height="100"
                  alt="Astrologer image"
                />
              </div>
            </div>

            <div className="h-[2rem] md:h-[2.4rem] bg-white absolute -bottom-[1rem] md:-bottom-[1.2rem] z-40 rounded-full shadow-md flex items-center justify-center">
              <img
                loading="lazy"
                src={med}
                width="20" height="20"
                className="object-contain ml-4 mr-2"
                alt="Heart filled"
              />
              <h6 className="worksans text-[0.8rem] md:text-sm text-black mr-4">
                Get Guidance
              </h6>
            </div>
          </div>
          <div className="absolute top-[8rem] md:top-48 left-[10rem] md:left-[21rem] h-[200px] md:h-[300px] w-[180px] md:w-[272px] bg-[#232148] flex flex-col items-center justify-between card p-2">
            <div className="rounded overflow-hidden w-full h-full bg-white/20 card p-[1px]">
              <div className="bg-[#232148] card h-full flex flex-col items-center justify-between rounded overflow-hidden">
                <div className="flex flex-col items-center">
                  <h2 className="text-white worksans font-semibold text-[0.8rem] md:text-lg tracking-wide mt-4">
                    Daily Chanting
                  </h2>
                  <h6 className="text-white worksans text-[0.7rem] md:text-base">
                    Daily Remedy
                  </h6>
                </div>
                <img loading="lazy" src={b2} className="object-contain mt-4" width="300" height="100" alt="Background 2"/>
              </div>
            </div>

            <div className="h-[2rem] md:h-[2.4rem] bg-white absolute -bottom-[1rem] md:-bottom-[1.2rem] z-40 rounded-full shadow-md flex items-center justify-center">
              <img
                loading="lazy"
                src={peace}
                width="20" height="20"
                className="object-contain ml-4 mr-2"
                alt="Peace of mind"
              />
              <h6 className="worksans text-[0.8rem] text-sm text-black mr-4 z-30">
                Peace of Mind
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="md:invisible visible h-auto md:h-0 w-full md:w-0 flex justify-center mt-16 md:mt-0">
        <button
          className="md:invisible visible md:h-0 h-[48px] but md:w-0 w-10/12 mt-4 transform transition duration-300 hover:scale-[1.02] hover:-translate-y-1 text-white tracking-wide worksans text-base md:text-lg"
          onClick={() => {
            navigate("/consultAstro");
          }}
        >
          {user.name !== undefined
            ? `Consult Top Astrologers Now!`
            : `Login & get ${
                user?.currencyType == "usd" ? "$ 4" : "₹ 91"
              } in your account!`}
        </button>
      </div>
      <div className="w-full flex justify-center mt-12 md:mt-3 z-10">
        <div className="bg-[#FFF7EE] h-auto md:h-28 w-10/12 md:w-2/3 lg:w-1/2 rounded-xl shadow-xl z-10 grid grid-cols-1 md:grid-cols-3 gap-4 py-4">
          <div className="flex flex-col items-center justify-center gap-2">
            <div className="flex justify-center gap-2 items-center">
              <h2 className="text-2xl font-semibold worksans text-black">
                4.9
              </h2>
              <div className="flex flex-col items-center justify-center w-20 overflow-hidden">
                <h4 className="worksans text-[0.7rem]">4K+ Reviews</h4>
                {/* <Stars className="h-3 object-contain" />
                 */}
                 <svg className="h-3 object-contain" width="104" height="16" viewBox="0 0 104 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.35939 0C8.00314 0 7.64689 0.184375 7.46252 0.55625L5.42189 4.69375L0.856265 5.35625C0.0375149 5.475 -0.29061 6.48438 0.30314 7.0625L3.60627 10.2812L2.82502 14.8281C2.68439 15.6438 3.54377 16.2656 4.27502 15.8813L8.35939 13.7375V0Z" fill="#FFC403"/>
                  <path d="M8.30711 0C8.66336 0 9.01961 0.184375 9.20399 0.55625L11.2446 4.69375L15.8102 5.35625C16.629 5.475 16.9571 6.48438 16.3634 7.0625L13.0602 10.2812L13.8415 14.8281C13.9821 15.6438 13.1227 16.2656 12.3915 15.8813L8.30711 13.7375V0Z" fill="#FFC403"/>
                  <path d="M30.0528 0C29.6965 0 29.3402 0.184375 29.1559 0.55625L27.1152 4.69375L22.5496 5.35625C21.7309 5.475 21.4027 6.48438 21.9965 7.0625L25.2996 10.2812L24.5184 14.8281C24.3777 15.6438 25.2371 16.2656 25.9684 15.8813L30.0528 13.7375V0Z" fill="#FFC403"/>
                  <path d="M30.0005 0C30.3567 0 30.713 0.184375 30.8973 0.55625L32.938 4.69375L37.5036 5.35625C38.3223 5.475 38.6505 6.48438 38.0567 7.0625L34.7536 10.2812L35.5348 14.8281C35.6755 15.6438 34.8161 16.2656 34.0848 15.8813L30.0005 13.7375V0Z" fill="#FFC403"/>
                  <path d="M51.7461 0C51.3899 0 51.0336 0.184375 50.8492 0.55625L48.8086 4.69375L44.243 5.35625C43.4242 5.475 43.0961 6.48438 43.6899 7.0625L46.993 10.2812L46.2117 14.8281C46.0711 15.6438 46.9305 16.2656 47.6617 15.8813L51.7461 13.7375V0Z" fill="#FFC403"/>
                  <path d="M51.6938 0C52.0501 0 52.4063 0.184375 52.5907 0.55625L54.6313 4.69375L59.197 5.35625C60.0157 5.475 60.3438 6.48438 59.7501 7.0625L56.447 10.2812L57.2282 14.8281C57.3688 15.6438 56.5095 16.2656 55.7782 15.8813L51.6938 13.7375V0Z" fill="#FFC403"/>
                  <path d="M73.4395 0C73.0832 0 72.727 0.184375 72.5426 0.55625L70.502 4.69375L65.9363 5.35625C65.1176 5.475 64.7895 6.48438 65.3832 7.0625L68.6863 10.2812L67.9051 14.8281C67.7645 15.6438 68.6238 16.2656 69.3551 15.8813L73.4395 13.7375V0Z" fill="#FFC403"/>
                  <path d="M73.3872 0C73.7434 0 74.0997 0.184375 74.2841 0.55625L76.3247 4.69375L80.8903 5.35625C81.7091 5.475 82.0372 6.48438 81.4434 7.0625L78.1403 10.2812L78.9216 14.8281C79.0622 15.6438 78.2028 16.2656 77.4716 15.8813L73.3872 13.7375V0Z" fill="#FFC403"/>
                  <path d="M95.1328 0C94.7766 0 94.4203 0.184375 94.236 0.55625L92.1953 4.69375L87.6297 5.35625C86.811 5.475 86.4828 6.48438 87.0766 7.0625L90.3797 10.2812L89.5985 14.8281C89.4578 15.6438 90.3172 16.2656 91.0485 15.8813L95.1328 13.7375V0Z" fill="#FFC403"/>
                  <path d="M97.5696 4.91491L95.5805 0.881937V13.4352L99.3973 15.4385L99.3976 15.4387C99.7635 15.631 100.192 15.3182 100.122 14.9131L100.122 14.9128L99.3409 10.3659L99.2964 10.1067L99.4847 9.92315L102.788 6.7044L102.788 6.70426C103.085 6.41514 102.92 5.91026 102.512 5.85107L102.512 5.85107L97.9462 5.18857L97.686 5.1508L97.5696 4.91491Z" stroke="#FFC403"/>
                </svg>  
              </div>
            </div>
            <h5 className="worksans text-black text-sm md:text-base">
              Rating On Playstore
            </h5>
          </div>
          <div className="flex flex-col items-center justify-center gap-2">
            <div className="flex gap-2">
              <h2 className="text-2xl font-semibold worksans text-black">
                1,00,000+
              </h2>
            </div>
            <h5 className="worksans text-black text-sm md:text-base">
              Trusting Users
            </h5>
          </div>
          <div className="flex flex-col items-center justify-center gap-2">
            <div className="flex gap-2">
              <h2 className="text-2xl font-semibold worksans text-black">
                400+
              </h2>
            </div>
            <h5 className="worksans text-black text-sm md:text-base">
              Verified Astrologers
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sec1;
