import React, {useState, useEffect, useContext} from 'react'
import { getwidgets } from '../services/apiServices';
import { AuthContext } from '../context/Auth';
const clone = require('rfdc')();

const emptyFilters = {
    rating: "",
    specialization: [],
    languages: [],
    price: "",
  };

function useWidgets(category, page, limit, setpage, defaultLanguages) {
    const { user } = useContext(AuthContext) || {user:{}};
    const [isLoading, setisLoading] = useState(true);
    const [error, seterror] = useState(false);
    const [widgets, setwidgets] = useState([]);
    const [hasMore, sethasMore] = useState(false);
    const [astrologerCount, setastrologerCount] = useState(0);
    const [totalConsultations, settotalConsultations] = useState(0);
    const [filters, setfilters] = useState({ ...emptyFilters });
    const [sort, setsort] = useState({
        title:"Relevance",
        sortBy:"relevance",
        sortOrder:"asc"
      });

    const resetFilters = ()=> {
        let fil = {
            rating: "",
            specialization: [],
            languages: [],
            price: "",
          };
        setfilters({...fil});
    }

    const fn = async () => {
        setisLoading(true);
        if(page==1) {
            setwidgets([]);
        }
        seterror(false);
        let res = await getwidgets(category, page, limit, user?.currencyType, sort, filters.languages, filters.price, filters.specialization, defaultLanguages);
        if (res.statusCode === 200) {
            if(page===1){
                setwidgets([...res.payLoad.consultWidgets ]);
            }
            else {
                setwidgets([...widgets, ...res.payLoad.consultWidgets ]);
            }
            setastrologerCount(res.payLoad.astrologerCount);
            settotalConsultations(res.payLoad.totalConsultations);
            sethasMore(res.payLoad.consultWidgets.length > 0);
        }
        else {
            seterror(true);
        }
        setisLoading(false);
    };

    useEffect(() => {
        if(page>0){
            fn();
        }
    }, [page]);

    useEffect(() => {
        if(page===1) {
            fn();
        }
        else {
            setpage(1);
        }
    }, [category, sort, filters, user?.currencyType]);

    const setStatus = (user, s) => {
        let index = -1;
        for(let i = 0; i <widgets.length;i++){
            if(widgets[i]?.data?.user===user){
                index=i;
                break;
            }
        }
        if(index!==-1){
            let w = clone(widgets);
            w[index].data.status = s;
            setwidgets([...w]);
        }
    }

    return {isLoading, error, widgets, hasMore ,astrologerCount, totalConsultations, setStatus, sort, setsort, filters, setfilters, resetFilters};
}

export default useWidgets
