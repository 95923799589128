import React, {useState} from "react";
import p1 from "../../staticAssets/p1.png";
import p2 from "../../staticAssets/p2.png";
import p3 from "../../staticAssets/p3.png";
import p4 from "../../staticAssets/p4.png";

const ps = [p1, p2, p3, p4];

function Sec4() {
  const [hiw, sethiw] = useState(0);

  return (
    <div className="h-auto md:h-screen container mx-auto flex flex-col justify-center mt-10 md:mt-0">
      <h1 className="text-black text-[25px] md:text-[50px] font-bold playfair text-center mb-6 md:mb-20">
        How it works
      </h1>
      <div className="flex mb-20 flex-col md:flex-row">
        <div className="w-screen md:w-1/2 flex items-center flex-col md:flex-row">
          <div className="invisible md:visible w-0 h-0 md:w-auto flex flex-row md:flex-col md:h-2/3 items-center">
            <div
              className={`p-4 border rounded-full h-12 w-12 flex items-center justify-center ${
                hiw == 0 ? "ngd text-white" : ""
              }`}
            >
              1
            </div>
            <div
              className={`h-0.5 md:h-auto w-auto md:w-0.5 flex-1 ${
                hiw == 0 ? "bg-[#FB5B1D]" : "bg-gray-500/50"
              }`}
            ></div>

            <div
              className={`p-4 border rounded-full h-12 w-12 flex items-center justify-center ${
                hiw == 1 ? "ngd text-white" : ""
              }`}
            >
              2
            </div>
            <div
              className={`h-0.5 md:h-auto w-auto md:w-0.5 flex-1 ${
                hiw == 1 ? "bg-[#FB5B1D]" : "bg-gray-500/50"
              }`}
            ></div>

            <div
              className={`p-4 border rounded-full h-12 w-12 flex items-center justify-center ${
                hiw == 2 ? "ngd text-white" : ""
              }`}
            >
              3
            </div>
            <div
              className={`h-0.5 md:h-auto w-auto md:w-0.5 flex-1 ${
                hiw == 2 ? "bg-[#FB5B1D]" : "bg-gray-500/50"
              }`}
            ></div>

            <div
              className={`p-4 border rounded-full h-12 w-12 flex items-center justify-center ${
                hiw == 3 ? "ngd text-white" : ""
              }`}
            >
              4
            </div>
          </div>
          <div className="h-auto md:h-3/4 w-full flex flex-row md:flex-col overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
            <div className="flex flex-row md:flex-col flex-nowrap lg:ml-10 md:ml-20 ml-2 justify-between h-full pb-6 md:pb-0">
              <button
                className="w-[80vw] md:w-auto bg-[#F7F7F7] shadow-md rounded-xl mr-4 flex flex-col"
                onClick={() => {
                  sethiw(0);
                }}
              >
                <div className="flex flex-col items:center md:items-start px-4 pt-4 w-full">
                  <div className="w-full flex justify-center md:hidden">
                    <div className="p-4 border rounded-full h-12 w-12 flex items-center justify-center ngd text-white">
                      1
                    </div>
                  </div>
                  <h6 className="font-semibold">Sign-up {"&"} add money!</h6>
                  <h6 className="text-[12px] text-center md:text-left">
                    Verify your phone number & use our offers to load your
                    wallet!
                  </h6>
                </div>
                <div className="md:invisible visible md:h-0 h-auto w-full md:w-0 flex justify-center item-center p-4 md:p-4">
                  <img
                    loading="lazy"
                    src={ps[0]}
                    width="200"
                    height="800"
                    className="object-contain h-[400px] md:h-[600px] drop-shadow-2xl w-full"
                    alt="Phone image 1"
                  />
                </div>
              </button>
              <button
                className="w-[80vw] md:w-auto bg-[#F7F7F7] shadow-md rounded-xl mr-4 flex flex-col"
                onClick={() => {
                  sethiw(1);
                }}
              >
                <div className="flex flex-col items-center md:items-start px-4 pt-4">
                  <div className="w-full flex justify-center md:hidden">
                    <div className="p-4 border rounded-full h-12 w-12 flex items-center justify-center ngd text-white">
                      2
                    </div>
                  </div>
                  <h6 className="font-semibold">Consult with Astrologers</h6>
                  <span className="text-[12px] text-center md:text-left">
                    Select from our wide variety of astrologers across problems{" "}
                    {"&"} languages
                  </span>
                </div>
                <div className="md:invisible visible md:h-0 h-auto w-full md:w-0 flex justify-center item-center p-4 md:p-4">
                  <img
                    loading="lazy"
                    width="200"
                    height="800"
                    src={ps[1]}
                    className="object-contain h-[400px] md:h-[600px] drop-shadow-2xl w-full"
                    alt="Phone image 2"
                  />
                </div>
              </button>
              <button
                className="w-[80vw] md:w-auto bg-[#F7F7F7] shadow-md rounded-xl mr-4 flex flex-col"
                onClick={() => {
                  sethiw(2);
                }}
              >
                <div className="flex flex-col items-center md:items-start px-4 pt-4">
                  <div className="w-full flex justify-center md:hidden">
                    <div className="p-4 border rounded-full h-12 w-12 flex items-center justify-center ngd text-white">
                      3
                    </div>
                  </div>
                  <h6 className="font-semibold">Get Remedy</h6>
                  <span className="text-[12px] text-center md:text-left">
                    Post consult follow our simple remedies to work towards your
                    goals!
                  </span>
                </div>
                <div className="md:invisible visible md:h-0 h-auto w-full md:w-0 flex justify-center item-center p-4 md:p-4">
                  <img
                    loading="lazy"
                    width="200"
                    height="800"
                    src={ps[2]}
                    className="object-contain h-[400px] md:h-[600px] drop-shadow-2xl w-full"
                    alt="Phone image 3"
                  />
                </div>
              </button>
              <button
                className="w-[80vw] md:w-auto bg-[#F7F7F7] shadow-md rounded-xl mr-4 flex flex-col"
                onClick={() => {
                  sethiw(3);
                }}
              >
                <div className="flex flex-col items-center md:items-start px-4 pt-4">
                  <div className="w-full flex justify-center md:hidden">
                    <div className="p-4 border rounded-full h-12 w-12 flex items-center justify-center ngd text-white">
                      4
                    </div>
                  </div>
                  <h6 className="font-semibold">Follow up</h6>
                  <span className="text-[12px] text-center md:text-left">
                    Our astrologers keep tabs on your progress. Use offers to
                    keep chatting with them!
                  </span>
                </div>
                <div className="md:invisible visible md:h-0 h-auto w-full md:w-0 flex justify-center item-center p-4 md:p-4">
                  <img
                    loading="lazy"
                    width="200"
                    height="800"
                    src={ps[3]}
                    className="object-contain h-[400px] md:h-[600px] drop-shadow-2xl w-full"
                    alt="Phone image 4"
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="invisible md:visible h-0 md:h-auto w-full md:w-1/2 flex justify-center item-center p-4">
          <img
            loading="lazy"
            src={ps[hiw]}
            className="object-contain h-[400px] md:h-[600px] drop-shadow-2xl w-full"
            alt="Phone Image"
          />
        </div>
      </div>
    </div>
  );
}

export default Sec4;
